/**
 * @Copyright 2021 @DigiNet
 * @Author QUOCUY
 * @Create 5/7/2021
 * @Example
 */

import React, { useRef, useState } from "react";
import { Modal, ModalHeader, ModalBody, Button, Typography, Notify, HelperText } from "diginet-core-ui/components";
import Config from "../../../../config";
import InputMask from "react-input-mask";
import { TextField } from "@material-ui/core";
import * as userActions from "../../../../redux/user/user_actions";
import { useDispatch } from "react-redux";
import W09F2003 from "../../W09/W09F2003/W09F2003";

const W00F1016 = ({ open, onClose, email }) => {
    const [code, setCode] = useState("");
    const [openPopupW09F2003, setOpenPopupW09F2003] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const dispatch = useDispatch();

    const Language = useRef(Config.language || "84");
    const alertRef = useRef(null);

    const handleChange = (key, e) => {
        if (!e) return false;
        switch (key) {
            case "Code":
                setCode(e.target?.value);
                break;

            default:
                break;
        }
    };

    const onResendCode = () => {
        setCode("")
        const params = {
            UserID: Config.profile?.UserID || "",
            HostID: "",
            Mode: 0,
            Language: Language.current,
            FormID: "W00F1016",
            Email: email,
        };
        dispatch(
            userActions.forgotPassword(params, async (errors, data) => {
                if (errors) {
                    const message = errors.message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                } else if (data) {
                    if (data.Status === 1) {
                        let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                        alertRef.current(message, {
                            color: "danger",
                            progressing: true,
                            autoDisappear: true,
                            clearAble: false,
                        });
                    } else {
                        alertRef.current(Config.lang("Gui_thanh_cong"), {
                            color: "success",
                            progressing: true,
                            autoDisappear: true,
                            clearAble: false,
                        });
                    }
                }
            })
        );
    };

    const onSubmit = () => {
        const params = {
            Language: Language.current,
            FormID: "W00F1016",
            Email: email,
            Code: code.replace(/ /g, ""),
        };
        setErrorMessage("");
        dispatch(
            userActions.checkSecurityCode(params, async (errors, data) => {
                if (errors) {
                    const message = errors.message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                } else if (data) {
                    if (data.Status === 1) {
                        let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                        setErrorMessage(message);
                    } else {
                        setOpenPopupW09F2003(true);
                    }
                }
            })
        );
    };

    const renderPopupW09F2003 = () => {
        return (
            <W09F2003
                forgotPassword
                email={email}
                code={code.replace(/ /g, "")}
                open={openPopupW09F2003}
                onClose={() => {
                    setOpenPopupW09F2003(false);
                    onClose();
                }}
            />
        );
    };
    return (
        <>
            <Modal onClose={onClose} open={open} pressESCToClose={false} moveable={false} dragAnyWhere={false} moveOutScreen={false} width={640} zIndex={1300}>
                <ModalHeader title={Config.lang("Nhap_ma_bao_mat")} />
                <ModalBody>
                    <Typography>
                        {Config.lang("He_thong_da_gui_ma_den_email")}: {email}
                    </Typography>
                    <Typography>{Config.lang("Vui_long_kiem_tra_email_va_nhap_ma_de_doi_mat_khau")}.</Typography>
                    <Typography>{Config.lang("Mat_khau_co_hieu_luc_trong_vong_5_phut")}.</Typography>
                    <div className="display_col valign-middle align-center">
                        <InputMask mask={"9 9 9 9 9 9"} maskChar={"_"} alwaysShowMask={true} value={code} onChange={e => handleChange("Code", e)}>
                            {props => {
                                return (
                                    <TextField
                                        {...props}
                                        autoFocus
                                        select={false}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        inputProps={{
                                            style: { textAlign: "center", fontSize: 50 },
                                            autoComplete: "new-password",
                                            form: {
                                                autocomplete: "off",
                                            },
                                        }}
                                    />
                                );
                            }}
                        </InputMask>
                        <HelperText children={errorMessage} className={errorMessage ? "mgt0 mgb5" : "hidden"} />
                    </div>

                    <div className="display_row valign-bottom align-center">
                        <Button color={"primary"} style={{ padding: 0, marginRight: "auto" }} text={Config.lang("Gui_lai_ma")} onClick={onResendCode} />
                        <Button
                            style={{ textTransform: "uppercase", minWidth: 105 }}
                            disabled={code.includes("_") || !code}
                            text={Config.lang("Tiep_tuc")}
                            viewType={"filled"}
                            color={"info"}
                            onClick={onSubmit}
                        />
                        <Button
                            style={{ textTransform: "uppercase", minWidth: 105, marginLeft: 8 }}
                            text={Config.lang("Huy")}
                            viewType={"outlined"}
                            onClick={onClose}
                        />
                    </div>
                </ModalBody>
            </Modal>
            {openPopupW09F2003 && renderPopupW09F2003()}
            <Notify getAddNotify={ref => (alertRef.current = ref)} />
        </>
    );
};

export default W00F1016;
