/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/11/2019
 * @Example
 */

import React from "react";
import Config from "../../../../config";
import PropTypes from "prop-types";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import * as w00f0010Actions from "../../../../redux/W0X/W00F0010/W00F0010_actions";
import {FormGroup, Row, Col} from "react-bootstrap";
import {
    FormLabel as Label,
} from "@material-ui/core";
import {Column} from "devextreme-react/data-grid";
import GridContainer from "../../../grid-container/grid-container";
import Combo from "../../../common/form-material/combo/combo";
import PopupSelect from "../../../popup/popup-select";
import ReorderIcon from '@material-ui/icons/Reorder';
import IconButton from "@material-ui/core/IconButton";
import Modal from "../../../common/modal/modal";
import W00F0010SlOrgChartActions from "./W00F0010SlOrgChart_Actions";
import ButtonGeneral from "../../../common/button/button-general";
import HeadClick from "../../../grid-container/head-click";

const styles = {
    textCell: {
        width: 'calc(100% - 33px)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
};
class W00F0010RolePermission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            ModuleIDs: [],
            dataGridRP: [],
            showPopupOrgChart: false,
            showPopupProject: false,
            showPopupSalaryGroup: false,
            isUpdate: false,

            defaultOrgChartIDs: [],
            total: 0
        };
        this.selectedRowIndx = 0;
        this.currentColumn = -1;
        this.postionFocused = {
            rowIndx: -1,
            colIndx: -1
        };
        this.tmpClipboard = null;
        this.filter = {
            limit: 50,
            skip: 0
        };
        this.selectedRange = {};
        this.filterColIndx = -1;
    }

    loadCboModules = () => {
        this.props.w00f0010Actions.getCboModuleW00F0010({}, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                this.setState({
                    ModuleIDs: [data[0].ModuleID]
                }, async () => {
                    this.loadGridRP();
                });
            }
        });
    };

    loadCboPermission = () => {
        this.props.w00f0010Actions.getCboPermissionW00F0010({}, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.loadGridRP();
        });
    };

    loadProjects = () => {
        this.props.w00f0010Actions.getProjectsW00F0010({}, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadSalaryGroups = () => {
        this.props.w00f0010Actions.getSalaryGroupsW00F0010({}, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadGridRP = () => {
        const {RoleID} = this.props;
        const {ModuleIDs} = this.state;
        if (!RoleID) return false;
        this.setState({loading: true});
        const param = {
            RoleID: RoleID,
            ModuleID: ModuleIDs && ModuleIDs.length > 0 ? JSON.stringify(ModuleIDs) : JSON.stringify([""]),
            // Language: Config.language || "84",
            // limit: this.filter.limit,
            // skip: this.filter.skip
        };
        this.props.w00f0010Actions.getGridRPW00F0010(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                // const {dataGridRP} = this.state;
                // const _dataGrid = isReseted ? data.rows ? data.rows : [] : dataGridRP.concat(data.rows);
                this.setState({
                    dataGridRP: data,
                    // total: data.total,
                    loading: false,
                });
            }
        });
    };

    componentDidMount() {
        this.loadCboModules();
        this.loadCboPermission();
    }

    handleChange = (name, e) => {
        switch (name) {
            case "Module":
                let value = e.value;
                if (value.length > 1) {
                    value = value.filter(v => v !== "%");
                }
                this.setState({
                    ModuleIDs: value,
                }, () => {
                    this.filter.skip = 0;
                    this.loadGridRP(true);
                });
                break;
            default:
                console.log('no change');
                break;
        }
    };

    showPopupOrgChart = (state, data) => {
        const value = data ? data.replace(/,/g, ';').split(';') : [];
        this.setState({
            showPopupOrgChart: state,
            defaultOrgChartIDs: value
        })
    };

    showPopupProject = (data) => {
        this.loadProjects();
        const value = data ? data.replace(/,/g, ';').split(';') : [];
        this.popupProject.open(value);
    };

    showPopupSalaryGroups = (data) => {
        this.loadSalaryGroups();
        const value = data ? data.replace(/,/g, ';').split(';') : [];
        this.popupSalaryGroup.open(value);
    };

    onSelectedPopup = (data, field) => {
        this.gridRolePermission.instance.cellValue(this.selectedRowIndx, field, data.join(';'));
        this.gridRolePermission.instance.cellValue(this.selectedRowIndx, "IsUpdated", 1);
        this.gridRolePermission.instance.saveEditData();
    };

    onSelectedOrgChartID = (data) => {
        this.showPopupOrgChart(false);
        this.gridRolePermission.instance.cellValue(this.selectedRowIndx, "OrgChartID", data.join(';'));
        this.gridRolePermission.instance.cellValue(this.selectedRowIndx, "IsUpdated", 1);
        this.gridRolePermission.instance.saveEditData();
    };

    onRowClick = (e) => {
        this.selectedRowIndx = e.rowIndex;
    };

    onSave = () => {
        const dataEdit = this.gridRolePermission.instance.saveEditData();
        dataEdit.done(() => {
            const dataSource = this.gridRolePermission.instance.option('dataSource');
            const dataUpdated = dataSource.filter((d) => {
                return d.IsUpdated === 1;
            });
            this._saveData(dataUpdated);

        });
    };

    _saveData = (data) => {
        const {onSaved, RoleID} = this.props;
        const param = {
            FormID: "W00F0010",
            RoleID: RoleID,
            data: JSON.stringify(data)
        };
        this.props.w00f0010Actions.saveGridRPW00F0010(param, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (onSaved) onSaved();

            Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
        });
    };

    cellRenderPopupAction = (cellData) => {
        const dataField = cellData.column.dataField,
            rowData = cellData.data;
        let onClick = null;
        switch (dataField) {
            case "OrgChartID":
                onClick = () => this.showPopupOrgChart(true, rowData[dataField]);
                break;
            case "ProjectID":
                onClick = () => this.showPopupProject(rowData[dataField]);
                break;
            case "SalEmpGroupID":
                onClick = () => this.showPopupSalaryGroups(rowData[dataField]);
                break;
            default:
                break;
        }
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div className={"pull-left mgr5"}>
                    <IconButton size={'small'} onClick={onClick}>
                        <ReorderIcon fontSize={"small"}/>
                    </IconButton>
                </div>
                <div className={"pull-left"} style={styles.textCell} title={cellData.value}>{cellData.value}</div>
            </div>
        );
    };

    onEditingStart = (e) => {
        const dataField = e.column.dataField,
            rowData = e.data;
        switch (dataField) {
            case "OrgChartID":
                this.showPopupOrgChart(true, rowData[dataField]);
                break;
            case "ProjectID":
                this.showPopupProject(rowData[dataField]);
                break;
            case "SalEmpGroupID":
                this.showPopupSalaryGroups(rowData[dataField]);
                break;
            default:
                break;
        }
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
    };

    onChangePerPage = (per) => {
        this.filter.skip = 0;
        this.filter.limit = per;
    };

    // loadMore = (cb) => {
    //     const {loading, total} = this.state;
    //     if (!loading && this.filter.skip + this.filter.limit < total) {
    //         this.filter.skip = this.filter.skip + this.filter.limit;
    //         this.loadGridRP();
    //     }
    //     if (cb) cb();
    // };

    onKeyDown = (e) => {
        const {event} = e;
        const {rowIndx, colIndx, column} = this.postionFocused;
        if (event && event.ctrlKey && column && column.allowEditing) {
            const cellValue = e.component.cellValue(rowIndx, colIndx);
            if (event.keyCode === 67) { //ctrl + C
                this.currentColumn = colIndx;
                this.tmpClipboard = cellValue;
            } else if (event.keyCode === 86) { //ctrl + v
                if ((this.tmpClipboard === 0 || this.tmpClipboard) && this.currentColumn === colIndx) {
                    e.component.cellValue(rowIndx, colIndx, this.tmpClipboard);
                }
            }
        } else if (event && event.keyCode === 46) {
            if (column && column.lookup) {
                if (column.lookup.allowClearing)
                    e.component.cellValue(rowIndx, colIndx, "");
            } else {
                e.component.cellValue(rowIndx, colIndx, "");
            }
        }
    };

    onFocusedCellChanging = (e) => {
        this.postionFocused = {
            column: e.columns[e.newColumnIndex],
            rowIndx: e.newRowIndex,
            colIndx: e.newColumnIndex
        };
        e.isHighlighted = true;
    };

    onSavePermissionAll = () => {
        const rows = this.gridRolePermission.instance.getVisibleRows();
        for(let item of rows) {
            this.gridRolePermission.instance.cellValue(item.rowIndex, "IsPermission", 4);
            this.gridRolePermission.instance.cellValue(item.rowIndex, "IsUpdated", 1);
        }
        this.gridRolePermission.instance.saveEditData();
    };

    renderHeaderColumns = (e) => {
        if (!e) return false;
        return <HeadClick selectedRange={this.selectedRange}
                          dataHeaderCell={e}
                          allowClick={true}
                          isGroup={true}
                          isCopyAll={true}
                          isAllData={true}
                          conditionCopy={(e) => {
                              const {key, data} = e;
                              if (key !== "IsPermission") {
                                  return data.IsPermission === 0 || data.IsPermission;
                              }
                              else return true;
                          }}
                          callbackAfterCopy={e => this.setState({isUpdate: e.IsUpdated})}
        />
    };

    setFocusFilter = (timeout = 1000) => {
        setTimeout(() => {
            if(this.filterColIndx > -1) {
                let el = document.getElementById('gridRolePermission');
                let containerFilter = el.getElementsByClassName("dx-header-multi-row");
                containerFilter     = containerFilter && containerFilter.length > 0 ? containerFilter[0] : null;
                if (containerFilter) {
                    let trTableFilter = containerFilter.getElementsByClassName('dx-datagrid-filter-row');
                    trTableFilter = trTableFilter && trTableFilter.length > 0 ? trTableFilter[1] : null;
                    if(trTableFilter) {
                        const cellFilter = trTableFilter.querySelector("td:nth-child(" + (this.filterColIndx + 1) + ")");
                        if (cellFilter) cellFilter.getElementsByTagName("input")[0].focus();
                    }
                }
                this.filterColIndx = -1;
            }
        },timeout)
    };

    onFilterGrid = (e) => {
        const {column} = e;
        const nameColumn = column?.dataField;
        switch (nameColumn) {
            case "ScreenID":
                this.filterColIndx = 2;
                break;
            case "ScreenName":
                this.filterColIndx = 3;
                break;
            case "IsPermission":
                this.filterColIndx = 4;
                break;
            case "OrgChartID":
                this.filterColIndx = 5;
                break;
            case "ProjectID":
                this.filterColIndx = 6;
                break;
            case "SalEmpGroupID":
                this.filterColIndx = 7;
                break;
            default:
                break;
        }
    };


    render() {
        const {loading, isUpdate, dataGridRP, ModuleIDs, showPopupOrgChart, showPopupProject, showPopupSalaryGroup,
            defaultOrgChartIDs
        } = this.state;
        const {getCboModules, getPermission, getProjects, getSalaryGroups} = this.props;
        const columnProjects = [
            {
                caption: Config.lang("Ma"),
                dataField: "ProjectID"
            },
            {
                caption: Config.lang("Ten"),
                dataField: "ProjectName"
            }
        ];
        const columnSalaryGroups = [
            {
                caption: Config.lang("Ma"),
                dataField: "SalEmpGroupID"
            },
            {
                caption: Config.lang("Ten"),
                dataField: "SalEmpGroupName"
            }
        ];

        // const arrTittle = [];
        // dataGridRP && dataGridRP.forEach((i)=>{if(!arrTittle.includes(i.ScreenTypeName)) arrTittle.push(i.ScreenTypeName);});

        return (
            <React.Fragment>
                <Modal open={showPopupOrgChart} maxWidth={"md"} fullWidth={true}
                       onClose={() => this.showPopupOrgChart(false)}
                       title={Config.lang("Chon_co_cau_to_chuc")}>
                    <W00F0010SlOrgChartActions
                        value={defaultOrgChartIDs}
                        onSave={this.onSelectedOrgChartID}
                        onClose={() => this.showPopupOrgChart(false)}/>
                </Modal>
                <PopupSelect
                    ref={ref => this.popupProject = ref}
                    typePaging={"normal"}
                    mode={'multiple'}
                    keyExpr={"ProjectID"}
                    title={Config.lang("Chon_du_an")}
                    open={showPopupProject}
                    dataSource={getProjects}
                    column={columnProjects}
                    allowFilterSelected
                    onSave={(data) => (this.onSelectedPopup(data, "ProjectID"))}
                    />
                <PopupSelect
                    ref={ref => this.popupSalaryGroup = ref}
                    typePaging={"remote"}
                    mode={'multiple'}
                    keyExpr={"SalEmpGroupID"}
                    title={Config.lang("Chon_nhom_luong")}
                    open={showPopupSalaryGroup}
                    dataSource={getSalaryGroups}
                    column={columnSalaryGroups}
                    allowFilterSelected
                    onSave={(data) => this.onSelectedPopup(data, "SalEmpGroupID")}
                />
                <FormGroup className={"row-form"}>
                    <Row>
                        <Col xs={12} sm={6} md={6} lg={6}>
                            <Combo
                                dataSource={getCboModules}
                                displayExpr={'ModuleName'}
                                valueExpr={'ModuleID'}
                                variant={'outlined'}
                                label={Config.lang('Module')}
                                name={'Module'}
                                value={ModuleIDs}
                                multiple
                                onValueChanged={(e) => this.handleChange("Module", e)}
                                />
                        </Col>
                        <Col xs={12} sm={6} md={2} lg={2}>
                            <ButtonGeneral name={Config.lang('Phan_quyen_tat_ca')}
                                           className={'pull-left mgt10'}
                                           onClick={this.onSavePermissionAll}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={4}>
                            <ButtonGeneral name={Config.lang('Luu')}
                                    typeButton={'save'}
                                    disabled={!isUpdate}
                                    className={'pull-right mgt10'}
                                    onClick={this.onSave}/>
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Label>{Config.lang('Phan_quyen')}</Label>
                </FormGroup>
                <FormGroup>
                    <GridContainer
                        keyExpr={"ScreenID"}
                        reference={ref => this.gridRolePermission = ref}
                        dataSource={dataGridRP}
                        columnAutoWidth={true}
                        itemPerPage={this.filter.limit}
                        skipPerPage={this.filter.skip}
                        listPerPage={[50, 100, 150]}
                        typeShort={true}
                        height={window.innerHeight - 280}
                        // totalItems={total}
                        loading={loading}
                        // loadMore={this.loadMore}
                        editing={{
                            mode: 'cell',
                            refreshMode: 'reshape',
                            // useIcons: true,
                            allowUpdating: true,
                            startEditAction: "dblClick"
                        }}
                        filterRow={{
                            visible: true,
                            showOperationChooser: false,
                        }}
                        elementAttr={{
                            id: "gridRolePermission"
                        }}
                        onFilterGrid={this.onFilterGrid}
                        onContentReady={() => {
                            this.setFocusFilter(500);
                        }}
                        allowCellSelection={true}
                        pagerFullScreen={false}
                        paging={{
                            enabled: false,
                        }}
                        sorting={{
                            mode: "none"
                        }}
                        // scrolling={{mode:"virtual"}}
                        typePaging={"normal"}
                        // pager={{visible: false}}
                        onEditingStart={this.onEditingStart}
                        onRowClick={this.onRowClick}
                        onFocusedCellChanging={this.onFocusedCellChanging}
                        onKeyDown={this.onKeyDown}
                        onRowUpdating={() => {
                            if (!this.state.isUpdate) this.setState({isUpdate: true});
                        }}
                        onRowUpdated={(e) => {
                            e.data.IsUpdated = 1;
                        }}
                        onCellSelectionChanged={(e) => {
                            if (e.selectedRange && e.selectedRange.rowType !== "header") {
                                this.selectedRange = e.selectedRange;
                            }
                        }}
                        // onChangePage={this.onChangePage}
                        // onChangePerPage={this.onChangePerPage}
                    >
                        <Column
                            dataField={'ScreenTypeName'}
                            groupIndex={0}
                            fixed={true}
                            allowFixing={true}
                            allowGrouping={true}
                            groupCellTemplate={(cellElement, cellInfo) => {
                                return cellElement.innerHTML = cellInfo.displayValue ? cellInfo.displayValue : Config.lang("Khong_ten");
                            }}
                        />
                        <Column caption={Config.lang("Man_hinh")}>
                            <Column
                                allowEditing={false}
                                width={150}
                            />
                            <Column
                                caption={Config.lang("Man_hinh")}
                                dataField={'ScreenID'}
                                alignment={"center"}
                                allowEditing={false}
                            />
                            <Column
                                caption={Config.lang("Ten_man_hinh")}
                                dataField={'ScreenName'}
                                allowEditing={false}
                            />
                        </Column>
                        <Column caption={Config.lang("Phan_quyen_chuc_nang")}>
                            <Column
                                caption={Config.lang("Quyen_han")}
                                dataField={'IsPermission'}
                                lookup={{
                                    dataSource: getPermission,
                                    valueExpr: "IsPermission",
                                    displayExpr: "PermissionName"
                                }}
                                // allowEditing={true}
                                allowCopying={true}
                                allowPasting={true}
                                headerCellRender={this.renderHeaderColumns}
                                width={200}>
                            </Column>
                        </Column>
                        <Column caption={Config.lang("Phan_quyen_du_lieu")}>
                            <Column
                                caption={Config.lang("Co_cau_to_chuc")}
                                dataField={'OrgChartID'}
                                width={200}
                                cellRender={(cellData) => this.cellRenderPopupAction(cellData)}
                                // allowEditing={true}
                                allowCopying={true}
                                allowPasting={true}
                                headerCellRender={this.renderHeaderColumns}
                            />
                            <Column
                                caption={Config.lang("Du_an")}
                                dataField={'ProjectID'}
                                width={200}
                                cellRender={(cellData) => this.cellRenderPopupAction(cellData)}
                                // allowEditing={true}
                                allowCopying={true}
                                allowPasting={true}
                                headerCellRender={this.renderHeaderColumns}
                            />
                            <Column
                                caption={Config.lang("Nhom_luong")}
                                width={200}
                                dataField={'SalEmpGroupID'}
                                cellRender={(cellData) => this.cellRenderPopupAction(cellData)}
                                // allowEditing={true}
                                allowCopying={true}
                                allowPasting={true}
                                headerCellRender={this.renderHeaderColumns}
                            />
                            <Column
                                caption={""}
                                dataField={'IsUpdated'}
                                dataType={'integer'}
                                visible={false}
                            />
                        </Column>
                    </GridContainer>
                </FormGroup>
                {/*<span className={"pull-right"}>*/}
                {/*    {Config.lang("Tong") + ": "}*/}
                {/*    <span style={dataGridRP.length >= total ? {color: 'red'} : {}}>{total}</span>*/}
                {/*</span>*/}
            </React.Fragment>
        );
    }
}

W00F0010RolePermission.propTypes = {
    RoleID: PropTypes.string,

    onSaved: PropTypes.func
};

export default compose(connect((state) => ({
        getCboModules: state.W00F0010.getCboModules,
        getPermission: state.W00F0010.getPermission,
        getProjects: state.W00F0010.getProjects,
        getSalaryGroups: state.W00F0010.getSalaryGroups,
    }),
    (dispatch) => ({
        w00f0010Actions: bindActionCreators(w00f0010Actions, dispatch)
}), null, {forwardRef: true}))(W00F0010RolePermission);
