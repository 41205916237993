/**
 * @copyright 2022 @ DigiNet
 * @author TRANGHOANG
 * @create 11/04/2022
 * @Example
 */

import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as mainActions from "../../../../redux/main/main_actions";
import * as homeActions from "../../../../redux/home/home_actions";
import * as W00F1000Actions from "../../../../redux/W0X/W00F1000/W00F1000_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W09F2091Actions from "../../../../redux/W0X/W09F2091/W09F2091_actions";

import Config from "../../../../config";
import W82F1000 from "../../../W8X/W82F1000/W82F1000";
import { Column } from "devextreme-react/data-grid";
import GridContainer from "../../../grid-container/grid-container";

import { makeStyles, useTheme } from "diginet-core-ui/theme";
import { Loading } from "../../../common/loading/loading";
import * as CryptoJS from "crypto-js";
import { Button, Typography, Image, Col, Row, ButtonIcon, Dropdown, Tooltip, Icon } from "diginet-core-ui/components";

const { spacing } = useTheme();

const useStyles = makeStyles(themes => ({
    container: {
        zIndex: 2,
        position: "fixed",
        maxWidth: "inherit",
        top: 50,
        marginTop: 0,
        paddingBottom: 40,
        height: "calc(100vh - 50px)",
        borderLeft: `1px solid ${themes.colors?.line?.category}`,
        transition: "0.2s all",
        width: open => (open ? (!Config.isMobile ? "50%" : "100%") : 0),
        right: 0,
        background: "white",
        alignItems: "flex-start",
        paddingRight: open => open && spacing(4),
    },
    content: {
        position: "relative",
        overflow: "hidden",
        width: "100%",
        minWidth: "100%",
        height: "100%",
        background: themes.colors?.white,
    },
    btnListAlt: {
        left: spacing(-16),
        top: spacing(34),
        width: spacing(16),
        height: spacing(12),
        position: "absolute",
        borderColor: `${themes.colors?.line?.category} !important`,
        backgroundColor: `${themes.colors?.white} !important`,
        borderRadius: spacing([1, 0, 0, 1]),
        justifyContent: "flex-start",
        ":hover": {
            width: `${spacing(20)}px !important`,
            left: `${spacing(-20)}px !important`,
        },
        "& .DGN-UI-Ripple": {
            pointerEvents: "none",
        },
    },
}));
const W00F1000 = props => {
    const { open, setOpen, allowNews } = props;
    const classes = useStyles(open);

    useEffect(() => {
        const dataShowHide = Config.parseJson(Config.getLocalStorage("W00F1000")) || {};
        dataShowHide[Config.profile.UserID] = open;
        Config.setLocalStorage("W00F1000", JSON.stringify(dataShowHide));
    }, [open]);

    const widthAnchorButton = useCallback(
        key => {
            return open === key ? spacing(20) : spacing(16);
        },
        [open]
    );

    const arrBtnAnchor = [
        {
            startIcon: "Announce",
            FormID: "W00F1000_01",
            top: spacing(20),
            hidden: !allowNews,
            title: Config.lang("Bang_tin_noi_bo"),
            icon: "Newspaper",
        },
        {
            startIcon: "ListAlt",
            FormID: "W00F1000_02",
            title: Config.lang("Canh_bao_nhac_viec"),
        },
    ];

    return (
        <Row className={classes.container}>
            {/*Button anchor*/}
            <Col xs={12}>
                {arrBtnAnchor.map((item, index) => {
                    index += 1;
                    if (item.hidden) return null;
                    return (
                        <div key={index}>
                            <Button
                                key={index}
                                startIcon={
                                    <Tooltip title={item.title}>
                                        <div className={"display_row"}>
                                            <Icon
                                                name={item.startIcon}
                                                color={open === item.FormID ? "danger" : "primary"}
                                            />
                                        </div>
                                    </Tooltip>
                                }
                                viewType={"outlined"}
                                color={"default"}
                                className={`${classes.btnListAlt}`}
                                onClick={() => {
                                    setOpen(item.FormID);
                                }}
                                style={{
                                    top: spacing(20 + index * 14),
                                    left: -widthAnchorButton(item.FormID),
                                    width: widthAnchorButton(item.FormID),
                                    borderRightWidth: open === item.FormID ? 0 : 1,
                                }}
                            />
                            {open === item.FormID && (
                                <Col xs={12} className={"display_row align-center align-between"}>
                                    <Typography type={"h1"} className={"align-center"}>
                                        <div className={"display_row"}>
                                            {item.icon && <Icon name={item.icon} className="mgr3x" />}
                                            {item.title}
                                        </div>
                                    </Typography>
                                    <ButtonIcon name={"Close"} onClick={() => setOpen()} circular viewType={"text"} />
                                </Col>
                            )}
                        </div>
                    );
                })}
            </Col>

            {/*Content*/}
            {/*<div className={classes.content}>*/}
            {open === "W00F1000_01" && allowNews && <W00F1000_01 {...props} />}
            {open === "W00F1000_02" && <W00F1000_02 {...props} />}
            {/*</div>*/}
        </Row>
    );
};

const W00F1000_02 = props => {
    const { getCboDivisions, currentModule } = props;
    const [dataSource, setDataSource] = useState(true);
    const [dataSourcePriod, setDataSourcePriod] = useState([]);
    const [dataMaster, setDataMaster] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDivision, setIsLoadingDivision] = useState(false);
    const [isLoadingPeriod, setIsLoadingPeriod] = useState(false);

    const [showW82F1000, setShowW82F1000] = useState(null);
    const [dataFilter, setDataFilter] = useState({ skip: 0, limit: 10 });

    useEffect(() => {
        loadDivision();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // khi chọn 1 trong 2 DD thì reset lưới về trang 0
        if (dataMaster.DivisionID && dataMaster.PeriodTime) {
            setDataFilter({ ...dataFilter, skip: 0 });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataMaster.PeriodTime]);

    useEffect(() => {
        // load loadPeriod lần đầu, load sau khi có DivisionID.
        if (dataMaster.DivisionID) loadPeriod();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataMaster.DivisionID]);

    useEffect(() => {
        loadGrid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataFilter, currentModule]);

    const loadDivision = () => {
        props.W09F2091Actions.getCboDivisions((error, data) => {
            setIsLoadingDivision(false);
            if (error) Config.popup.show("ERROR", error);
            if (data.length) {
                // default DivisionID đầu tiên
                setDataMaster({ ...dataMaster, DivisionID: data[0].DivisionID });
            }
        });
    };

    const loadPeriod = useCallback(() => {
        const params = {
            DivisionID: dataMaster.DivisionID,
        };
        setIsLoadingPeriod(true);
        props.generalActions.getCboPeriod(params, (error, data) => {
            if (error) Config.popup.show("ERROR", error);
            setIsLoadingPeriod(false);
            setDataSourcePriod(data);
            if (data.length && data[0].PeriodTime !== dataMaster.PeriodTime) {
                // default PeriodTime đầu tiên
                setDataMaster({ ...dataMaster, PeriodTime: data[0].PeriodTime });
            } else {
                setDataFilter({ ...dataFilter, skip: 0 });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataMaster]);

    const loadGrid = () => {
        const { PeriodTime } = dataMaster;
        if (!PeriodTime) return;
        setIsLoading(true);
        const params = {
            ...dataFilter,
            ...dataMaster,
            ModuleID: currentModule?.ModuleID || "ALL",
            TranMonth: Number(PeriodTime.split("/")[0] || null),
            TranYear: Number(PeriodTime.split("/")[1] || null),
        };
        props.W00F1000Actions.getList(params, (err, data) => {
            setIsLoading(false);
            if (err) Config.popup.show("ERROR", err);
            else setDataSource(data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const onChangePage = page => {
        setDataFilter({ ...dataFilter, skip: page * dataFilter.limit });
    };

    const onChangePerPage = per => {
        setDataFilter({ ...dataFilter, skip: 0, limit: per });
    };

    const onRowClick = e => {
        const { data } = e;
        setShowW82F1000({ ...data, ...dataFilter, ...dataMaster });
    };

    const onCloseW82F1000 = () => {
        setShowW82F1000(null);
    };

    const handleChange = (key, e) => {
        if (!key || !e) return false;
        const value = e?.target?.value || e?.value || e;
        setDataMaster({ ...dataMaster, [key]: value });
    };

    return (
        <>
            <Col xs={12} md={6}>
                <Dropdown
                    valueExpr={"DivisionID"}
                    displayExpr={"DivisionName"}
                    label={Config.lang("Don_vi")}
                    onChange={e => handleChange("DivisionID", e)}
                    dataSource={getCboDivisions}
                    loading={isLoadingDivision}
                    value={dataMaster?.DivisionID}
                />
            </Col>
            <Col xs={12} md={6}>
                <Dropdown
                    label={Config.lang("Ky")}
                    valueExpr={"PeriodTime"}
                    displayExpr={"PeriodTime"}
                    placeholder={"MM/YYYY"}
                    onChange={e => handleChange("PeriodTime", e)}
                    loading={isLoadingPeriod}
                    dataSource={dataSourcePriod}
                    value={dataMaster.PeriodTime}
                />
            </Col>
            <Col xs={12}>
                <GridContainer
                    keyExpr={"AlertCode"}
                    totalItems={dataSource.total}
                    itemPerPage={dataFilter.limit}
                    skipPerPage={dataFilter.skip}
                    dataSource={dataSource.rows}
                    showBorders={false}
                    height={Config.getHeightGrid() - 100}
                    showColumnLines={false}
                    hoverStateEnabled={true}
                    onChangePage={onChangePage}
                    onChangePerPage={onChangePerPage}
                    loading={isLoading}
                    onRowClick={onRowClick}
                >
                    <Column
                        caption={Config.lang("Ten_canh_bao")}
                        dataField={"AlertMessage"}
                        calculateCellValue={rowData => `${rowData.AlertMessage} (${rowData.Quantity})`}
                    />
                    <Column width={"20%"} minWidth={150} caption={Config.lang("Ma_canh_bao")} dataField={"AlertCode"} />
                    <Column dataField={"AlertBaseID"} visible={false} />
                    <Column dataField={"Quantity"} visible={false} />
                    <Column dataField={"SQLQuery"} visible={false} />
                    <Column dataField={"IsAlertByEmail"} visible={false} />
                </GridContainer>

                {/*Popup W82F1000*/}
                {showW82F1000 && <W82F1000 data={showW82F1000} open={!!showW82F1000} onClose={onCloseW82F1000} />}
            </Col>
        </>
    );
};

const W00F1000_01 = props => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        loadListEOffice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadListEOffice = () => {
        const params = {
            Type: "LW6",
        };
        setIsLoading(true);
        props.homeActions.getListEOffice(params, (error, data) => {
            setIsLoading(false);
            if (error) Config.popup.show("ERROR", error);
            setData(data);
        });
    };

    const onClickEOffice = item => {
        const eOfficeLink = Config.getSetting("EOFFICE_URL");
        const UserID = Config.profile.UserID;
        const PassW = "";
        let url = "/";
        if (eOfficeLink && UserID) {
            if (item && item.TransID) {
                url = "/W76F2142/channel?task=detail&newsID=" + item.TransID;
            }
            let data = {
                userName: UserID,
                password: PassW,
                url: url,
                isWeb6: 1,
            };
            const CryptoJSAesJson = {
                stringify: function (cipherParams) {
                    const j = { x: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
                    if (cipherParams.iv) j.y = cipherParams.iv.toString();
                    if (cipherParams.salt) j.z = cipherParams.salt.toString();
                    return JSON.stringify(j);
                },
                parse: function (jsonStr) {
                    const j = JSON.parse(jsonStr);
                    const cipherParams = CryptoJS.lib.CipherParams.create({
                        ciphertext: CryptoJS.enc.Base64.parse(j.x),
                    });
                    if (j.y) cipherParams.iv = CryptoJS.enc.Hex.parse(j.y);
                    if (j.z) cipherParams.salt = CryptoJS.enc.Hex.parse(j.z);
                    return cipherParams;
                },
            };
            data = JSON.stringify(data);
            const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), "Lem@nWebCorporation", {
                format: CryptoJSAesJson,
            }).toString();
            data = btoa(encrypted);
            window.open(eOfficeLink + "/callback/?p=" + data);
        }
        return false;
    };

    return (
        <Col xs={12} style={{ overflow: "auto", maxHeight: "100%" }}>
            <Loading visible={isLoading} size={"sm"} />
            {data?.map((item, idx) => {
                const temp = document.createElement("div");
                temp.innerHTML = item.Description || "";
                const description = temp.textContent || temp.innerText;

                //Image..
                let urlObject = null;
                const arrayBuffer = item && item.Image ? item.Image.data : null;
                const content = new Uint8Array(arrayBuffer);
                if (content) {
                    urlObject = URL.createObjectURL(new Blob([content.buffer]));
                }
                return (
                    <Col key={idx} className={"pdb4x"}>
                        <div className={"display_row"}>
                            <Image
                                src={urlObject}
                                width={104}
                                height={104}
                                className={"mgr4x"}
                                style={{
                                    borderRadius: 5,
                                    minWidth: 104,
                                    minHeight: 104,
                                }}
                            />
                            <div>
                                <Typography
                                    type={"h3"}
                                    lineClamp={2}
                                    onClick={() => onClickEOffice(item)}
                                    className={"mgb1x"}
                                >
                                    {item.Title}
                                </Typography>
                                <Typography type={"p2"} lineClamp={2} className={"mgb1x"}>
                                    {description}
                                </Typography>
                                <Button viewType={"link"} onClick={() => onClickEOffice(item)} size={"tiny"}>
                                    {Config.lang("Xem_them")}
                                </Button>
                            </div>
                        </div>
                    </Col>
                );
            })}
        </Col>
    );
};

export default connect(
    state => ({
        getListEOffice: state.home.getListEOffice,
        currentModule: state.home.currentModule,
        getCboDivisions: state.W09F2091.getCboDivisions,
    }),
    dispatch => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        homeActions: bindActionCreators(homeActions, dispatch),
        W00F1000Actions: bindActionCreators(W00F1000Actions, dispatch),
        W09F2091Actions: bindActionCreators(W09F2091Actions, dispatch),
    }),
    null,
    { forwardRef: true }
)(W00F1000);
