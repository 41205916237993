/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/11/2019
 * @Example 
 */

import React from "react";
import Config from "../../../../config";
import PropTypes from "prop-types";
import {FormGroup, Row, Col} from "react-bootstrap";
import {
    FormLabel as Label,
} from "@material-ui/core";
import DropDownOrganization from "../../../common/dropdown/dropdown-treeview";
import ButtonGeneral from "../../../common/button/button-general";

class W00F0010SlOrgChartActions extends React.Component {
    constructor(props) {
        super(props);
        this.tmpOrgChartID = props.value ? props.value : [];
    }

    onSave = () => {
        const {onSave} = this.props;
        if (onSave) onSave(this.tmpOrgChartID);
    };

    render() {
        const {value, onClose} = this.props;

        return (
            <React.Fragment>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            {onClose && <ButtonGeneral name={Config.lang('DHR_Dong1')}
                                         typeButton={"cancel"}
                                         className={'pull-right'}
                                         style={{ textTransform: 'uppercase' }}
                                         onClick={() => {
                                             if (onClose) onClose();
                                         }}
                                         size={"medium"}
                            />}
                            <ButtonGeneral name={Config.lang('DHR_Luu')}
                                           typeButton={'save'}
                                           style={{ textTransform: 'uppercase' }}
                                           size={"medium"}
                                           className={'pull-right mgr5'}
                                           onClick={this.onSave}/>
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={3} md={3} lg={3}>
                            <Label className={"form control-label"}>{Config.lang("DHR_Co_cau_to_chuc")}</Label>
                        </Col>
                        <Col xs={12} sm={9} md={9} lg={9}>
                            <DropDownOrganization
                                value={value}
                                label={Config.lang("DHR_Chon_co_cau_to_chuc")}
                                showClearButton={true}
                                selectionMode={"multiple"}
                                typeDefault={"organizational"}
                                onValueChanged={(e, data) => {
                                    this.tmpOrgChartID = data;
                                }}/>
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        );
    }
}

W00F0010SlOrgChartActions.propTypes = {
    value: PropTypes.any,

    onSave: PropTypes.func,
    onClose: PropTypes.func
};

export default W00F0010SlOrgChartActions;
