/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/31/2019
 * @Example
 */

import React, {useEffect, useRef, useState, useImperativeHandle} from "react";
import Config                                                          from "../../../../config";
import PropTypes                                                       from "prop-types";
import {bindActionCreators, compose}                                   from "redux";
import {connect}                                                       from "react-redux";
import * as w00f0010Actions                                            from "../../../../redux/W0X/W00F0010/W00F0010_actions";
import {FormGroup, Row, Col}                                           from "react-bootstrap";
import {
    FormLabel as Label,
    Card, CardContent, Checkbox, FormControlLabel
}                                                                      from "@material-ui/core";
import {Column}              from "devextreme-react/data-grid";
import GridContainer         from "../../../grid-container/grid-container";
import {Combo}               from "../../../common/form-material";
import Skeleton              from "@material-ui/lab/Skeleton";
import {useStyles}           from "./styles";

const W00F0010ListRoles = React.forwardRef( (props, ref) => {
    const classes = useStyles();
    const {getCboDatabaseName, getGridListRoles} = props;
    const [loading, setLoading] = useState(false);
    const [skeleton, setSkeleton] = useState(true);
    const [databaseName, setDatabaseName] = useState(null);
    const [Disabled, setDisabled] = useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const [CopRoleID, setCopRoleID] = useState('');

    const roleIDRef = useRef(null);
    let filterColIndx = -1;

    useImperativeHandle(
        ref,
        () => ({
            updateRoleID(RoleID) {
                const dbName = getCboDatabaseName && getCboDatabaseName.length > 0 ? getCboDatabaseName[0].CompanyID : "";
                roleIDRef.current = RoleID;
                if (dbName !== databaseName) {
                    setDatabaseName(dbName);
                } else {
                    setSelectedRowKeys([RoleID]);
                    roleIDRef.current = null;
                }
            },
        }),
    );

    const loadComboDatabaseName = () => {
        const param = {
            Language: Config.language || '84'
        };
        props.w00f0010Actions.getCboDatabaseName(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data && data.length > 0) {
                let defaultValue = "";
                data.forEach((e) => {
                    if (e.IsChoose) {
                        defaultValue = e;
                    }
                });
                setDatabaseName(defaultValue ? defaultValue.CompanyID : data[0].CompanyID);
            }
            setSkeleton(false);
        });
    };

    const loadGridListRoles = () => {
        const {onSelect, filterData} = props;
        if (databaseName) {
            const param = {
                DatabaseName: databaseName ? databaseName : "",
                skip: 0,
                limit: 1000,
                Disabled: Disabled,
                Language: Config.language || 84,
                ...filterData
            };
            setLoading(true);
            props.w00f0010Actions.getListRoles(param, (error, data) => {
                if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
                if (onSelect) {
                    if (data && data.rows && data.rows.length > 0) {
                        setSelectedRowKeys([data.rows[0].RoleID]);
                        onSelect(data.rows[0].RoleID);
                    } else {
                        onSelect("");
                    }
                }
                if (roleIDRef.current) {
                    setSelectedRowKeys([roleIDRef.current]);
                    roleIDRef.current = null;
                }
                setLoading(false);
            });
        }
    };

    useEffect( loadComboDatabaseName, []);

    useEffect(loadGridListRoles, [databaseName, Disabled]);

    const saveDataPastesRole = (e) => {
        if(!e) return false;
        const {reloadGrid} = props;
        const {data} = e.row;
        const param = {
            RoleID: data && data.RoleID ? data.RoleID : "",
            CopRoleID: CopRoleID || ""
        };
        setLoading(true);
        props.w00f0010Actions.savePastesRole(param, (error) => {
            setLoading(false);
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if(reloadGrid) reloadGrid();
            Config.notify.show('success', Config.lang("DHR_Luu_thanh_cong"), 2000);

        });
    };

    const renderDisabled = (e) => {
        return (
            <Checkbox classes={{root: classes.checkbox}} checked={!!e.value} color="primary"/>
        );
    };

    const handleDatabaseChange = (e) => {
        setDatabaseName(e.value);
    };

    const onChangedKSD = (e) => {
        setDisabled(e.target.checked ? Number(e.target.value) : 0);
    };

    const onSelectionChanged = (e) => {
        const {selectedRowsData, selectedRowKeys} = e;
        setSelectedRowKeys(selectedRowKeys);
        const {onSelect} = props;
        if (onSelect && selectedRowsData && selectedRowsData.length > 0) {
            onSelect(selectedRowsData[0].RoleID);
        }
    };
    const onContextMenuPreparing = (e) => {
        if(!e) return false;
        const {data} = e.row;
        if (e.target === "content") {
            if (!e.items) e.items = [];
            e.items.push(
                {
                    disabled: disableButton,
                    text: Config.lang("DHR_Sao_chep_quyen"),
                    onItemClick: () => {
                        setDisableButton(true);
                        setCopRoleID(data.RoleID);
                    }
                },
                {
                    disabled: !disableButton,
                    text: Config.lang("DHR_Dan_quyen") + " " + (disableButton ? "("+ CopRoleID+ ")" : ""),
                    onItemClick: () => {
                        saveDataPastesRole(e);
                    }
                },
                {
                    disabled: !disableButton,
                    text: Config.lang("DHR_Bo_sao_chep") + " " + (disableButton ?   "("+ CopRoleID+ ")" : ""),
                    onItemClick: () => {
                        setDisableButton(false);
                        setCopRoleID("");
                    }
                },
            );
        }
    };

    const setFocusFilter = (timeout = 1000) => {
        setTimeout(() => {
            if(filterColIndx > -1) {
                let el = document.getElementById('listRole');
                let containerFilter = el.getElementsByClassName("dx-datagrid-filter-row");
                containerFilter     = containerFilter && containerFilter.length > 0 ? containerFilter[0] : null;
                if (containerFilter) {
                    const cellFilter = containerFilter.querySelector("td:nth-child(" + (filterColIndx + 1) + ")");
                    if (cellFilter) cellFilter.getElementsByTagName("input")[0].focus();
                }
                filterColIndx = -1;
            }
        },timeout)
    };

    return (
        <div>
            {skeleton ? (
                <Skeleton height={800} variant="rect"/>
            ) : (
                <Card className={classes.root}>
                    <CardContent>
                        <FormGroup className={'row-header'}>
                            <Label>
                                <span className={"card-header"}>{Config.lang('DHR_Danh_sach_vai_tro')}</span>
                            </Label>
                        </FormGroup>
                        <FormGroup className={"row-form"}>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Combo
                                        dataSource={getCboDatabaseName}
                                        displayExpr={"CompanyID"}
                                        valueExpr={"CompanyID"}
                                        stylingMode={"outlined"}
                                        margin={"dense"}
                                        disabled={loading}
                                        value={databaseName}
                                        shrink={true}
                                        label={Config.lang('Database')}
                                        onValueChanged={handleDatabaseChange}
                                    />
                                    {/*<Combo*/}
                                    {/*    dataSource={getCboDatabaseName}*/}
                                    {/*    labelIndx={'CompanyID'}*/}
                                    {/*    disableClearable={true}*/}
                                    {/*    label={Config.lang('Database')}*/}
                                    {/*    value={databaseName}*/}
                                    {/*    defaultValue={databaseName}*/}
                                    {/*    onChange={this.handleDatabaseChange}*/}
                                    {/*/>*/}
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup className={'row-header'} style={{overflow: 'hidden'}}>
                            <Label className={"pull-left mgb10"}>
                                <span className={"card-header"}>{Config.lang('DHR_Vai_tro')}</span>
                            </Label>
                            <FormControlLabel
                                className={"pull-right mgb10"}
                                control={<Checkbox classes={{root: classes.checkbox}} color={'primary'} onChange={onChangedKSD} value={1} />}
                                label={Config.lang('DHR_Hien_thi_vai_tro_khong_con_su_dung')}
                                classes={{label: classes.labelCheckbox}}>
                            </FormControlLabel>
                        </FormGroup>
                        <div className={"clsfx"} />
                        <FormGroup>
                            <GridContainer
                                dataSource={getGridListRoles?.rows || []}
                                columnAutoWidth={true}
                                keyExpr={'RoleID'}
                                loadPanel={{
                                    enabled: loading
                                }}
                                paging={{
                                    pageSize: 15
                                }}
                                filterRow={{
                                    visible: true,
                                    showOperationChooser: false,
                                }}
                                elementAttr={{
                                    id: "listRole"
                                }}
                                onFilterGrid={(e) => {
                                    const {columnIndex} = e;
                                    filterColIndx = columnIndex;
                                }}
                                onContentReady={() => {
                                    setFocusFilter(500);
                                }}
                                typeShort={true}
                                onContextMenuPreparing={onContextMenuPreparing}
                                selectedRowKey={selectedRowKeys}
                                onSelectionChanged={onSelectionChanged}
                            >
                                <Column
                                    caption={Config.lang("DHR_Ma_vai_tro")}
                                    dataField={'RoleID'}
                                />
                                <Column
                                    caption={Config.lang("DHR_Ten_vai_tro")}
                                    dataField={'RoleName'}
                                />
                                <Column
                                    caption={'KSD'}
                                    dataField={'Disabled'}
                                    width={'150'}
                                    alignment={'center'}
                                    cellRender={renderDisabled}
                                />
                                <Column
                                    dataField={'CreateUserID'}
                                    visible={false}
                                />
                                <Column
                                    dataField={'CreatedDate'}
                                    visible={false}
                                />
                                <Column
                                    dataField={'LastModifyUserID'}
                                    visible={false}
                                />
                                <Column
                                    dataField={'LastModifyDate'}
                                    visible={false}
                                />
                            </GridContainer>
                        </FormGroup>
                    </CardContent>
                </Card>
            )}
        </div>
    );
    // }
});

W00F0010ListRoles.propTypes = {
    roleID: PropTypes.string,
    onSelect: PropTypes.func
};

export default compose(connect((state) => ({
        getCboDatabaseName: state.W00F0010.getCboDatabaseName,
        getGridListRoles: state.W00F0010.getGridListRoles,
        getOrganizational: state.W00F0010.getOrganizational
    }),
    (dispatch) => ({
        w00f0010Actions: bindActionCreators(w00f0010Actions, dispatch),
    }), null, {forwardRef: true}
))(W00F0010ListRoles);
