import Config from '../../config/index'

// map key between CORE and UI
const arrKey = {
    "dropFileHere": "Keo_va_tha_tap_tin_dinh_kem_vao_day"
};

export const setLocalizeCore = (localize) => {

    // list language
    const arrLanguage = Object.keys(localize).filter(key=> key!=='localizeTimeStamps');

    let localizeCoreUI = {};
    arrLanguage.forEach(i=>{
        localizeCoreUI[i] = {};
        Object.keys(arrKey).forEach(key=>{
            localizeCoreUI[i][key] = localize?.[i][`${Config?.env?.prefixLang}${arrKey[key]}`];
        })
    });
    return localizeCoreUI;
};


